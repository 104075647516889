<template>
  <div class="accountManage">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="accountManage--breadcrumb"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">
        <font-awesome-icon icon="home" style="margin-right:10px;"/>首頁
      </el-breadcrumb-item  >
          
      <el-breadcrumb-item>
        <font-awesome-icon icon="search-dollar" style="margin-right:10px;"/>帳號管理
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="accountManage__listBox">
      <div class="accountManage__listBox--Content">
        <el-table :data="tableData" ref="multipleTable" style="width: 100%"  empty-text="暫無數據">
          <el-table-column type="index" label="序號" width="60" ></el-table-column>
          <el-table-column label="使用者名稱" prop="UserName" width="150"></el-table-column>
          <el-table-column label="使用者角色" prop="Role" width="150"></el-table-column>
          <el-table-column label="上次登錄時間">
            <template slot-scope="scope">{{ filterDate(scope.row.lastLoginTime) }}</template>
          </el-table-column>
          <el-table-column fixed="right" label="變更密碼" width="150">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="變更密碼" placement="left" v-if="canEdit(scope.row)">
                <el-button @click.native.prevent="changePWDModal(scope.row)" style="background-color:transparent;border:0px;">
                  <font-awesome-icon icon="edit" size="lg"/>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    
    <!-- 變更密碼 -->
    <el-dialog title="變更密碼" :visible.sync="changePWD_func" width="60%" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"  >
        <el-form-item label="請輸入舊密碼" prop="oldPWD">
          <el-input v-model="ruleForm.oldPWD" show-password></el-input>
        </el-form-item>

        <el-form-item label="請輸入新密碼" prop="newPWD">
          <el-input v-model="ruleForm.newPWD" show-password></el-input>
        </el-form-item>

        <el-form-item label="請再次輸入新密碼" prop="checkPWD">
          <el-input v-model="ruleForm.checkPWD" show-password></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="changePWD_func = false">取消</el-button>
        <el-button type="primary" @click="changePWD()">儲存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var changePWDValidatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填欄位"));
      } else if (value !== this.ruleForm.newPWD) {
        callback(new Error("兩次密碼輸入不一致"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],
      /* 變更密碼 */
      changePWD_func: false,
      changePWDID: "",
      ruleForm: {
        oldPWD: "",
        newPWD: "",
        checkPWD: "",
      },
      rules: {
        oldPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        newPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        checkPWD: [
          {
            required: true,
            validator: changePWDValidatePass2,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm");
      };
    },
    canEdit(){
      return (row) => {
        let state = false
        if(row.UserID === window.localStorage.getItem("userID")){
          state = true
        }
        return state
      };
    }
  },
  methods: {
    changePWDModal(item) {
      this.changePWDID = item.UserID;
      this.changePWD_func = true;
    },
    formValidate(form) {
      let status = false;
      this.$refs[form].validate((valid) => {
        if (valid) {
          status = true;
        }
      });
      return status;
    },
    changePWD() {
      if (!this.formValidate("ruleForm")) return;
      this.$confirm("確定要變更密碼嗎?", "變更提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const pwdQuery = {
            UserID: this.$store.state.userInfo.UserID,
            Password: this.ruleForm.newPWD,
            IsUser:true
          };
          this.$api.user.updateUserPassword(pwdQuery).then((res) => {
            (this.ruleForm = {
              oldPWD: "",
              newPWD: "",
              checkPWD: "",
            }),
              console.log(res);
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
            });
            this.changePWD_func = false;
          });
        })
        .catch(() => {
          this.$notify({
            type: "info",
            message: "已取消修改密碼",
            type: "warning",
          });
        });
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      let temp = {
        ComName:this.$store.state.userInfo.ComName
      }
      if(this.$store.state.userInfo.Role==='operator'){
        temp.UserID = this.$store.state.userInfo.UserID
      }
      this.$api.user.getInfo(temp).then((res)=>{
        console.log(res);
        if(res.data.length > 0){

          this.tableData = res.data
        }
        this.$store.dispatch("loadingHandler", false);
      })
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss">
.accountManage {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--breadcrumb {
    padding: 20px 40px;
  }

  &__title {
    width: 100%;
    padding-top: 20px;
    text-align: center;

    strong {
      color: #191972;
      letter-spacing: 2px;
      font-size: 1.1rem;
    }
  }

  &__listBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &--add {
      span {
        transition: 0.6s;
        cursor: pointer;
        i,
        a {
          font-size: 18px;
          font-weight: bold;
          color: #191972;
        }
        i {
          padding-right: 4px;
        }

        &:hover {
          letter-spacing: 2px;
        }
      }
    }

    &--Content {
      width: 75%;

      &--add {
        width: 100%;
        //padding: 0 30px;
        box-sizing: border-box;
        text-align: right;

        span {
          transition: 0.6s;
          cursor: pointer;
          i,
          a {
            font-size: 18px;
            font-weight: bold;
            color: #191972;
          }
          i {
            padding-right: 4px;
          }

          &:hover {
            letter-spacing: 2px;
          }
        }
      }

      &--userFunc {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
